.carousel-mini-wrapper{
  width: 250px;
}
  .container-carousel-mini {
    margin: 200px 0 400px 0;
    width: 250px;
    height: 200px;
    perspective: 1500px;
  }
  
  .carousel-mini {
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
  }
  .carousel-mini div {
    transform-style: preserve-3d;
    
  }
  
  .item {
    display: block;
    position: absolute;
    background:transparent;
    width: 100%;
    height: 200px;
    color: #FFF;
    opacity: 1;
    border-radius: 10px;
    transition: transform 1s;
  }
  
  .a {
    transform: rotateY(0deg) translateZ(500px) rotateY(0deg);
  }
  
  .b {
    transform: rotateY(60deg) translateZ(500px) rotateY(-60deg);
  }
  
  .c {
    transform: rotateY(120deg) translateZ(500px)  rotateY(-120deg);
  }
  
  .d {
    transform: rotateY(180deg) translateZ(500px) rotateY(-180deg);
  }
  
  .e {
    transform: rotateY(240deg) translateZ(500px) rotateY(-240deg);
  } 
 
  .f {
    transform: rotateY(300deg) translateZ(500px) rotateY(-300deg);
  }
  @media  (max-width: 900px) {
    .carousel-mini-wrapper{
      /* width: 350px; */
      
    }
      .container-carousel-mini {
        margin: 200px 0 400px 0;
        width: 250px;
        height: 200px;
        perspective: 1000px;
      }
      
      .carousel-mini {
        height: 100%;
        position: absolute;
        transform-style: preserve-3d;
        transition: transform 1s;
      }
      .carousel-mini div {
        transform-style: preserve-3d;
        
      }
      
      .item {
        /* display: block;
        position: absolute;
        background:transparent;
        width: 250px;
        height: 200px;
        color: #FFF;
        opacity: 1;
        border-radius: 10px;
        transition: transform 1s; */
        width: 15%;
        height: 300px;
        background-position: 50% 50%;
        display: inline-block;
        transition: 0.8s;
        background-size: cover;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 20px;
        box-shadow: 0 30px 50px #505050;

      }
      
      .a {
        transform: rotateY(0deg) translateZ(50px) rotateY(0deg);
      }
      
      .b {
        transform: rotateY(60deg) translateZ(50px) rotateY(-60deg);
      }
      
      .c {
        transform: rotateY(120deg) translateZ(50px)  rotateY(-120deg);
      }
      
      .d {
        transform: rotateY(180deg) translateZ(50px) rotateY(-180deg);
      }
      
      .e {
        transform: rotateY(240deg) translateZ(50px) rotateY(-240deg);
      } 
     
      .f {
        transform: rotateY(300deg) translateZ(50px) rotateY(-300deg);
      }
  }
  @media  (max-width: 200) {
    .carousel-mini-wrapper{
      width: 50%;
      /* width: 45%; */
    }
      .container-carousel-mini {
        margin: 200px 0 400px 0;
        width: 150px;
        height: 200px;
        perspective: 1000px;
        padding: 50px;
      }
      
      .carousel-mini {
        height: 100%;
        position: absolute;
        transform-style: preserve-3d;
        transition: transform 1s;
      }
      .carousel-mini div {
        transform-style: preserve-3d;
        
      }
      
      .item {
        /* display: block;
        position: absolute;
        background:transparent;
        width: 250px;
        height: 200px;
        color: #FFF;
        opacity: 1;
        border-radius: 10px;
        transition: transform 1s; */
        width: 80%;
        height: 300px;
        background-position: 50% 50%;
        display: inline-block;
        transition: 0.8s;
        background-size: cover;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 20px;
        box-shadow: 0 30px 50px #505050;
      }
      
      .a {
        transform: rotateY(0deg) translateZ(200px) rotateY(0deg);
      }
      
      .b {
        transform: rotateY(60deg) translateZ(200px) rotateY(-60deg);
      }
      
      .c {
        transform: rotateY(120deg) translateZ(200px)  rotateY(-120deg);
      }
      
      .d {
        transform: rotateY(180deg) translateZ(200px) rotateY(-180deg);
      }
      
      .e {
        transform: rotateY(240deg) translateZ(200px) rotateY(-240deg);
      } 
     
      .f {
        transform: rotateY(300deg) translateZ(200px) rotateY(-300deg);
      }
  }