.container {
    width: 100%;
    height: 900px;
    background-color: #f5f5f5;
    box-shadow: 0 30px 50px #a9a8a8;
  }
  #slide {
    width: max-content;
    margin-top: 50px;
  }
  .item-main {
    /* width: 5%; */
    height: 300px;
    background-position: 50% 50%;
    display: inline-block;
    transition: 0.8s;
    background-size: cover;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 20px;
    box-shadow: 0 30px 50px #505050;
    
  }
  /* .item:nth-child(1), */
  .item-main:nth-child(2) {
    left: 0;
    top: 0;
    transform: translate(0, 0);
    border-radius: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
  .item-main:nth-child(3) {
    left: 55%;
    height: 300px;
    width: 10%;
    filter:brightness(0.7)
  }
  .item-main:nth-child(4) {
    left: 65%;
    height: 400px;
    width: 15%;
    filter:brightness(0.7)
  }
  .item-main:nth-child(5) {
    left: 75%;
    height: 500px;
    width: 20%;
    filter:brightness(0.7)
  }
  .item-main:nth-child(n + 6) {
    left: 85%;
    opacity: 0;
  }
  .item-main .content {
    position: absolute;
    top: 50%;
    left: 5%;
    width: 45%;
    height: fit-content;
    min-height: 300px;
    border-radius: 30px;
    text-align: left;
    padding: 10px;
    color: #eee;
    transform: translate(0, -50%);
    display: none;
  }
  .item-main:nth-child(2) .content {
    display: flex;
    flex-direction: column;
      justify-content: center;
      align-items: center;
    z-index: 11111;
    animation: showBg 1s ease-in-out 1 forwards;

  }
  .item-main .name {
    font-size: 50px;
    font-weight: bold;
    margin-left: 20px;
    opacity: 0;
    animation: showcontent 1s ease-in-out 1 forwards;
  }
  .item-main .des {
    font-size: 20px;
    margin: 20px 20px;
    opacity: 0;
    animation: showcontent 1s ease-in-out 0.3s 1 forwards;
  }
  .item-main button {
    border: none;
    opacity: 0;
    animation: showcontent 1s ease-in-out 0.6s 1 forwards;
  }
  @keyframes showcontent {
    from {
      opacity: 0;
      transform: translate(0, 100px);
      filter: blur(33px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
      filter: blur(0);
    }
  }
  @keyframes showBg {
    from {
      opacity: 0;
      filter: blur(33px);
    }
    to {
      opacity: 1;
      filter: blur(0);
      
    background-color: rgba(0,0,0,0.5)
    }
  }
  .buttons {
    position: absolute;
    border: none;
    bottom: 30px;
    z-index: 222222;
    left: 50%;
  }
  .buttons button {
    width: 50px;
    height: 50px;
    background-image: url("../../assets/images/scrollDown.gif");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 50%;
    transition: 0.5s;
  }
  @media only screen and (max-width: 825px) {
    .container {
      width: fit-content;
      height: 100vh;
      min-height: 900px;
      background-color: #f5f5f5;
      box-shadow: 0 30px 50px #a9a8a8;
    }
    #slide {
      margin-top: 50px;
    }
    .item-main {
      /* height: 300px; */
      display: inline-block;
      transition: 0.8s;
      background-size: cover;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 20px;
      box-shadow: 0 30px 50px #505050;
      
    }
    .item-main:nth-child(2) {
      left: 0;
      top: 0;
      transform: translate(0, 0);
      border-radius: 0;
      width: 100%;
      height: 100%;
      box-shadow: none;
    }
    .item-main:nth-child(3) {
      
      display: none;
    }
    .item-main:nth-child(4) {
     
      display: none;
    }
    .item-main:nth-child(5) {

      display: none;
    }
    .item-main:nth-child(n + 6) {

      display: none;
    }
    .item-main .content {
      position: absolute;
      top: 50%;
      left: 5%;
      width: 90%;
      height: fit-content;
      min-height: 400px;
      border-radius: 30px;
      text-align: center;
      padding: 0;
      color: #eee;
      transform: translate(0, -50%);
      display: none;
      background-color: rgba(0,0,0,0.5)
    }
    .item-main:nth-child(2) .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 11111;
    }
    .item-main .name {
      font-size: 40px;
      font-weight: bold;
      margin-left: 20px;
      opacity: 0;
      animation: showcontent 1s ease-in-out 1 forwards;
    }
    .item-main .des {
      font-size: 16px;
      margin: 20px 20px;
      opacity: 0;
      animation: showcontent 1s ease-in-out 0.3s 1 forwards;
    }
    .item-main button {
      border: none;
      opacity: 0;
      animation: showcontent 1s ease-in-out 0.6s 1 forwards;
    }
    @keyframes showcontent {
      from {
        opacity: 0;
        transform: translate(0, 100px);
        filter: blur(33px);
      }
      to {
        opacity: 1;
        transform: translate(0, 0);
        filter: blur(0);
      }
    }
    .buttons {
      position: absolute;
      border: none;
      bottom: 10px;
      z-index: 222222;
      left: 45%;
    }
    .buttons button {
      width: 50px;
      height: 50px;
      background-image: url("../../assets/images/scrollDown.gif");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 50%;
      transition: 0.5s;
      left: 45%;
    }
  }