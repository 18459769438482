@font-face {
  font-family: 'Poiret One';
  src: url('../src/assets/fonts/PoiretOne-Regular.ttf') format('truetype');
}
.main-children{
  margin-top:-120px;
}
.carousel.carousel-slider{
  height:100vh;
}
.carousel-main{
  background-color: #3c3c3c;
}
.App{
  font-family: 'Poiret One', sans-serif !important;
}
.multiItemClass{
  padding: 0 !important;
  margin: 0 !important;
}
.carouselContainer{
  width:100% !important;
  height:100% !important;
  padding: 0 !important;
  margin: 0 !important;

}
.react-multi-carousel-track:last-child {
  margin-right: 0 !important;
}
.nav-links{
  text-decoration: none;
  margin-right: 50px;
  font-size: 1.2rem;
 
}
.nav-links:hover{
  color:#00AA00 !important;
}
.offer-modal{
  position: fixed;
  bottom: 30;
  right: 40;
}